import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [0,12,13,14,15,4,5,6,7,8,10];

export const dictionary = {
		"/(main)": [16,[2],[3]],
		"/about": [54,[11]],
		"/adm/(baseperm)": [55,[12,13]],
		"/adm/(baseperm)/characters": [56,[12,13]],
		"/adm/(baseperm)/characters/[id]": [57,[12,13]],
		"/adm/coupons/[coupon_id]": [~60,[12,14]],
		"/adm/events": [61,[12,15]],
		"/adm/events/[event_id]": [~62,[12,15]],
		"/adm/(baseperm)/pictures": [58,[12,13]],
		"/adm/(baseperm)/pictures/[pic_id]": [~59,[12,13]],
		"/(main)/characters": [17,[2],[3]],
		"/(main)/characters/[character_id]": [~18,[2,4],[3]],
		"/(main)/characters/[character_id]/drawings": [19,[2,4],[3]],
		"/(main)/characters/[character_id]/fursuits": [20,[2,4],[3]],
		"/(main)/characters/[character_id]/photos": [21,[2,4],[3]],
		"/(main)/coupons": [22,[2],[3]],
		"/(main)/drawings": [23,[2],[3]],
		"/(main)/events": [24,[2],[3]],
		"/(main)/events/[event_id]": [~25,[2,5],[3]],
		"/(main)/events/[event_id]/fursuit": [26,[2,5],[3]],
		"/(main)/events/[event_id]/participants": [27,[2,5],[3]],
		"/(main)/events/[event_id]/photos": [28,[2,5],[3]],
		"/(main)/fursuits": [29,[2],[3]],
		"/(main)/fursuits/create": [34,[2],[3]],
		"/(main)/fursuits/[fursuit_id]": [~30,[2,6],[3]],
		"/(main)/fursuits/[fursuit_id]/events": [31,[2,6],[3]],
		"/(main)/fursuits/[fursuit_id]/events/[event_id]": [~32,[2,6],[3]],
		"/(main)/fursuits/[fursuit_id]/photos": [33,[2,6],[3]],
		"/(main)/makers": [35,[2],[3]],
		"/(main)/makers/[maker_id]": [~36,[2,7],[3]],
		"/(main)/makers/[maker_id]/fursuits": [37,[2,7],[3]],
		"/(main)/notifications": [38,[2],[3]],
		"/(main)/oauth/callback": [39,[2],[3]],
		"/(main)/oauth/success": [40,[2],[3]],
		"/(main)/photos": [41,[2],[3]],
		"/(main)/pictures/[pic_id]": [~42,[2,8],[3]],
		"/(main)/pictures/[pic_id]/edit": [43,[2,8],[3]],
		"/(main)/post": [44,[2],[3]],
		"/(main)/settings": [~45,[2,9],[3]],
		"/(main)/settings/advanced": [46,[2,9],[3]],
		"/(main)/settings/layout": [47,[2,9],[3]],
		"/(main)/users": [48,[2],[3]],
		"/(main)/users/[user_id]": [~49,[2,10],[3]],
		"/(main)/users/[user_id]/characters": [50,[2,10],[3]],
		"/(main)/users/[user_id]/drawings": [51,[2,10],[3]],
		"/(main)/users/[user_id]/liked": [52,[2,10],[3]],
		"/(main)/users/[user_id]/photos": [53,[2,10],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';